
import { defineComponent, ref } from '@vue/composition-api'
import { mdiCheck } from '@mdi/js'

import CommonNewPasswordInput from '@/components/common/CommonNewPasswordInput.vue'

import { useNotify } from '@/store'

import { useSetPassword } from '@/api/auth'

import { ValidationForm } from '@/utils/types/validation'

export default defineComponent({
  name: 'SetPasswordView',
  components: {
    CommonNewPasswordInput,
  },
  props: {
    token: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup: (props, { root }) => {
    if (!props.token) root.$router.push('/login')

    const { setPassword, isLoading } = useSetPassword()
    const { addNotification } = useNotify()

    const setPasswordForm = ref<null | ValidationForm>(null)

    const newPassword = ref('')

    const changePassword = () => {
      setPassword({ resetToken: props.token, password: newPassword.value })
        .then(() => {
          addNotification({
            type: 'success',
            text: root.$t('changePassword.success') as string,
          })

          root.$router.push('/login')
        })
        .catch((error) => {
          error.userMessage = root.$t('changePassword.error') as string
          throw error
        })
    }

    return {
      icons: { mdiCheck },
      newPassword,
      setPasswordForm,
      changePassword,
      isLoading,
    }
  },
})
